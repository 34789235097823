import React from 'react';
import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';
import Menu from './components/Menu';

import { PriceUpdates, Houses, Favorites, HouseDetail, Login, Register } from './pages';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

import { FilterContextProvider, UserContextProvider } from './Context';

/* Theme variables */
import './theme/variables.css';

const App: React.FC = () => {
  return (
    <UserContextProvider>
      <IonApp>
        <IonReactRouter>
          <IonSplitPane contentId="main">
            <FilterContextProvider>
              <Menu />
              <IonRouterOutlet id="main">
                <Route path="/login" component={Login} exact />
                <Route path="/register" component={Register} exact />
                <Route path="/houses" component={Houses} exact />
                <Route path="/price-updates" component={PriceUpdates} exact />
                <Route path="/favorites" component={Favorites} exact />
                <Route path="/house/:slug" component={HouseDetail} exact />
                <Redirect from="/" to="/houses" exact />
              </IonRouterOutlet>
            </FilterContextProvider>
          </IonSplitPane>
        </IonReactRouter>
      </IonApp>
    </UserContextProvider>
  );
};

export default App;
