import { UpdateModel } from './update.model';

export class EntryModel {
  id: string = '';
  houseId: string = '';
  address: string = '';
  originalAddress: string = '';
  imageUrl: string = '';
  link: string = '';
  place: string = '';
  price: string = '';
  size: string = '';
  label: string = '';
  updates: UpdateModel[] = [];
  createdAt: string = '';
  timeLabel: string = '';
  newPrice: string | null = null;
  favorite: Boolean = false;
  entries: any[] = [];

  constructor(data?: any) {
    if (!data) return;

    if (data._id) {
      this.id = data._id;
    }
    if (data.houseId) {
      this.houseId = data.houseId;
    }
    if (data.address) {
      this.address = data.address;
    }
    if (data.originalAddress) {
      this.originalAddress = data.originalAddress;
    }
    if (data.imageUrl) {
      this.imageUrl = data.imageUrl;
    }
    if (data.place) {
      this.place = data.place;
    }
    if (data.price) {
      this.price = data.price;
    }
    if (data.size) {
      this.size = data.size;
    }
    if (data.updates) {
      this.updates = [...data.updates].reverse().map((update: any) => new UpdateModel(update));
      this.newPrice = this.updates.find((update) => update.price)?.price || null;
    }
    this.label = this.updates.find((update) => update.label)?.label || data.label || null;
    this.link = data.link;
    if (data.createdAt) {
      this.createdAt = data.createdAt;
      const d = new Date(this.createdAt);
      const datestring = `${('0' + d.getDate()).slice(-2)}.${('0' + (d.getMonth() + 1)).slice(-2)}.${d.getFullYear()}`;
      this.timeLabel = `from ${datestring} |`;
    }
    if (data.favorite) {
      this.favorite = data.favorite;
    }
    if (data.entries) {
      this.entries = data.entries;
    }
  }
}
