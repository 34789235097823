import React from 'react';
import { IonItem, IonLabel, IonListHeader, IonBadge, IonIcon } from '@ionic/react';
import { radioButtonOff, radioButtonOn } from 'ionicons/icons';
import { useQuery } from 'react-query';
import { fetchNeighbourhoods } from '../utils';

import { FilterContext } from '../Context';

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const Neighbourhoods: React.FC = () => {
  const { filters, updateFilters } = React.useContext(FilterContext) as FilterContextType;
  const { searchText, minPrice, maxPrice, minSize, maxSize } = filters;

  const { error, data: neighbourhoods } = useQuery<INeighbourhood[] | null, any>(
    ['neighbourhoods', { searchText, minPrice, maxPrice, minSize, maxSize }],
    fetchNeighbourhoods
  );
  const [selectedNeighbourhoods, setSelectedNeighbourhoods] = React.useState<string[]>([]);

  const handleNeighbourhoodClick = (name: string) => () => {
    if (selectedNeighbourhoods.includes(name)) {
      const newSelectedNeighbourhoods = selectedNeighbourhoods.filter((neighbourhood) => neighbourhood !== name);
      setSelectedNeighbourhoods(newSelectedNeighbourhoods);
      return;
    }
    setSelectedNeighbourhoods([...selectedNeighbourhoods, name]);
  };

  React.useEffect(() => {
    console.log('selectedNeighbourhoods', selectedNeighbourhoods);
    updateFilters({ neighbourhoods: selectedNeighbourhoods });
  }, [selectedNeighbourhoods]);

  if (error) {
    return null;
  }

  return (
    <>
      <IonListHeader>
        <IonLabel>Neighbourhoods</IonLabel>
      </IonListHeader>
      {neighbourhoods &&
        neighbourhoods.map((neighbourhood) => (
          <IonItem button key={neighbourhood.name} onClick={handleNeighbourhoodClick(neighbourhood.name)}>
            <IonIcon
              slot="start"
              icon={selectedNeighbourhoods.includes(neighbourhood.name) ? radioButtonOn : radioButtonOff}
            />
            <IonLabel>{neighbourhood.name}</IonLabel>
            <IonBadge slot="end" color="light">
              {neighbourhood.count}
            </IonBadge>
          </IonItem>
        ))}
    </>
  );
};

export default Neighbourhoods;
