import React from 'react';
import { IonItem, IonLabel, IonInput, IonList, IonRange, IonListHeader } from '@ionic/react';
import { useLocation } from 'react-router-dom';
import { FilterContext } from '../../Context';
import Neighbourhoods from '../Neighbourhoods';
import { numberWithCommas } from '../../utils';

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const Filters: React.FC = () => {
  const { filters, updateFilters } = React.useContext(FilterContext) as FilterContextType;
  const location = useLocation();
  const [priceRange, setPriceRange] = React.useState<{
    lower: number;
    upper: number;
  }>({ lower: filters.minPrice, upper: filters.maxPrice });
  const [sizeRange, setSizeRange] = React.useState<{
    lower: number;
    upper: number;
  }>({ lower: filters.minSize, upper: filters.maxSize });
  const [searchText, setSearchText] = React.useState<string>(filters.searchText);

  React.useEffect(() => {
    if (filters.searchText !== searchText) {
      setSearchText(filters.searchText);
    }
    if (filters.minPrice !== priceRange.lower || filters.maxPrice !== priceRange.upper) {
      setPriceRange({
        lower: filters.minPrice,
        upper: filters.maxPrice,
      });
    }
    if (filters.minSize !== sizeRange.lower || filters.maxSize !== sizeRange.upper) {
      setSizeRange({
        lower: filters.minSize,
        upper: filters.maxSize,
      });
    }
  }, [filters]);

  React.useEffect(() => {
    updateFilters({
      minPrice: priceRange.lower,
      maxPrice: priceRange.upper,
      minSize: sizeRange.lower,
      maxSize: sizeRange.upper,
      searchText,
    });
  }, [searchText, priceRange, sizeRange]);

  const handlePriceRangeChange = (e: any) => {
    const newRange = e.detail.value as any;
    if (newRange.lower === priceRange.lower && newRange.upper === priceRange.upper) {
      return;
    }
    setPriceRange(e.detail.value as any);
  };

  const handleSizeRangeChange = (e: any) => {
    const newRange = e.detail.value as any;
    if (newRange.lower === sizeRange.lower && newRange.upper === sizeRange.upper) {
      return;
    }
    setSizeRange(e.detail.value as any);
  };

  const handleSearchChange = (e: any) => {
    setSearchText(e.detail.value as any);
  };

  if (!location.pathname.includes('houses')) {
    return null;
  }

  return (
    <IonList>
      <IonItem lines="none">
        <IonLabel>Filters</IonLabel>
      </IonItem>
      {/* <IonListHeader>
        <IonLabel>Filters</IonLabel>
      </IonListHeader> */}
      <IonItem>
        <IonLabel position="stacked">Search</IonLabel>
        <IonInput value={searchText} onIonChange={handleSearchChange} />
      </IonItem>
      <IonListHeader lines="none" style={{ marginBottom: -10, paddingBottom: 0 }}>
        <IonLabel>Price Range</IonLabel>
      </IonListHeader>
      <IonItem>
        <IonRange
          value={priceRange}
          debounce={300}
          dualKnobs={true}
          min={0}
          max={1500000}
          step={25000}
          snaps={false}
          pin
          onIonChange={handlePriceRangeChange}
        >
          <IonLabel slot="start">{numberWithCommas(priceRange.lower)}€</IonLabel>
          <IonLabel slot="end">{numberWithCommas(priceRange.upper)}€</IonLabel>
        </IonRange>{' '}
      </IonItem>
      <IonListHeader lines="none" style={{ marginBottom: -10, paddingBottom: 0 }}>
        <IonLabel>Size Range</IonLabel>
      </IonListHeader>
      <IonItem>
        <IonRange
          value={sizeRange}
          debounce={300}
          dualKnobs={true}
          min={0}
          max={300}
          step={5}
          snaps={false}
          pin
          // color="secondary"
          onIonChange={handleSizeRangeChange}
        >
          <IonLabel slot="start">{sizeRange.lower} m²</IonLabel>
          <IonLabel slot="end">{sizeRange.upper} m²</IonLabel>
        </IonRange>
      </IonItem>
      <Neighbourhoods />
    </IonList>
  );
};

export default Filters;
