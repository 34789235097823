import React, { Fragment, useState } from 'react';
import { IonItemGroup, IonItemDivider, IonItem, IonButton } from '@ionic/react';

export interface UpdatesProps {
  woz?: IWOZ;
}

const Woz: React.FC<UpdatesProps> = ({ woz }) => {
  const [collapsed, setCollapsed] = useState<Boolean>(true);

  const handleClick = (shouldCollapse: Boolean) => (event: React.MouseEvent) => {
    event.stopPropagation();
    setCollapsed(shouldCollapse);
  };

  if (!woz) {
    return <IonItemDivider color="light">No WOZ</IonItemDivider>;
  }

  return (
    <Fragment>
      <IonItemGroup>
        <IonItemDivider color="light">
          WOZ {woz.adres} | {woz.oppervlakte}
          <IonButton slot="end" color="dark" fill="clear" onClick={handleClick(!collapsed)}>
            {collapsed ? 'All' : 'Less'}
          </IonButton>
        </IonItemDivider>
        {woz.values &&
          Object.entries(woz.values).map(
            ([key, value], index, array) =>
              value &&
              (!collapsed || index === 0) && (
                <IonItem key={key} lines={index === (collapsed ? 0 : array.length - 1) ? 'none' : 'inset'}>
                  {key.replace(/_/g, '.')}:&nbsp;<b>{woz.values[key]}</b>
                </IonItem>
              )
          )}
      </IonItemGroup>
    </Fragment>
  );
};

export default Woz;
