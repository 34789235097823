import React, { useState, Fragment } from 'react';
import { IonButton, IonLoading } from '@ionic/react';
import { fetchWoz } from '../../utils/api';
import { queryCache } from 'react-query';

interface WozButtonProps {
  id: string;
}

const WozButton: React.FC<WozButtonProps> = ({ id }) => {
  const [state, setState] = useState<string | null>(null);
  const handleClick = async (event: React.MouseEvent) => {
    event.stopPropagation();
    setState('loading');
    const data = await fetchWoz(id);
    console.log({ data });
    setState('done');

    queryCache.refetchQueries('houseDetail');
    queryCache.refetchQueries('houses');
    queryCache.refetchQueries('favoriteHouses');
  };

  return (
    <Fragment>
      <IonButton disabled={state === 'loading'} onClick={handleClick} color="light">
        Get WOZ value
      </IonButton>
      <IonLoading
        // cssClass="my-custom-class"
        isOpen={state === 'loading'}
        // onDidDismiss={() => setShowLoading(false)}
        message={'Please wait...'}
        // duration={5000}
      />
    </Fragment>
  );
};

export default WozButton;
