import React, { Fragment, useState } from 'react';
import { IonItemGroup, IonItemDivider, IonItem, IonButton } from '@ionic/react';
import { UpdateModel } from '../../models/update.model';

export interface UpdatesProps {
  updates: UpdateModel[];
}

const Updates: React.FC<UpdatesProps> = ({ updates }) => {
  const [collapsed, setCollapsed] = useState<Boolean>(true);

  const handleClick = (shouldCollapse: Boolean) => () => {
    setCollapsed(shouldCollapse);
  };

  if (!updates || !updates.length) {
    return <IonItemDivider color="light">No Updates</IonItemDivider>;
  }

  if (collapsed) {
    const [lastUpdate] = updates;
    const { id, createdAt, ...other } = lastUpdate;
    return (
      <Fragment>
        <IonItemGroup>
          <IonItemDivider color="light">Update from {new Date(createdAt).toDateString()}</IonItemDivider>
          {Object.entries(other).map(
            ([key, value]) =>
              value && (
                <IonItem key={key}>
                  {key}: {value}
                </IonItem>
              )
          )}
        </IonItemGroup>
        {updates.length > 1 && (
          <IonItemGroup>
            <IonItem>
              <IonButton color="dark" onClick={handleClick(false)}>
                {updates.length - 1} more updates
              </IonButton>
            </IonItem>
          </IonItemGroup>
        )}
      </Fragment>
    );
  }
  return (
    <Fragment>
      {updates.map(({ id, createdAt, ...other }: UpdateModel) => (
        <IonItemGroup key={id}>
          <IonItemDivider color="light">UPDATE from {new Date(createdAt).toDateString()}</IonItemDivider>
          {Object.entries(other).map(
            ([key, value]) =>
              value && (
                <IonItem key={key}>
                  {key}: {value}
                </IonItem>
              )
          )}
        </IonItemGroup>
      ))}
      <IonItemGroup>
        <IonItem>
          <IonButton color="dark" onClick={handleClick(true)}>
            show less updates
          </IonButton>
        </IonItem>
      </IonItemGroup>
    </Fragment>
  );
};

export default Updates;
