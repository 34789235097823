import React from 'react';
import { useQuery } from 'react-query';
import { IonContent, IonItemDivider, IonList, IonPage } from '@ionic/react';
import { useParams } from 'react-router';
import Header from '../components/Header';

import { HouseModel } from '../models/house.model';
import { fetchHouseDetail, fetchHouses } from '../utils';
import HouseItem from '../components/HouseList/HouseItem';
import Updates from '../components/HouseList/Updates';
import Map from '../components/Map';

interface SimilarHousesProps {
  address: string;
}
function SimilarHouses({ address }: SimilarHousesProps) {
  const [houses, setHouses] = React.useState<HouseModel[]>([]);
  const [searchText] = address.match(/\D+\d+/) || [];

  useQuery(['houseDetail', 0, { searchText }], fetchHouses, {
    onSuccess: (data) => {
      const similarHouses = data.houses?.filter((house) => house.address !== address);
      if (similarHouses?.length) {
        setHouses(similarHouses);
      }
    },
  });

  if (!houses.length) {
    return null;
  }

  return (
    <React.Fragment>
      <IonItemDivider color="light">Similar Housess</IonItemDivider>
      {houses && houses.map((house: HouseModel) => <HouseItem key={house.id} house={house} />)}
    </React.Fragment>
  );
}

const HouseDetail: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const { error, data: house, isFetching } = useQuery<HouseModel | null, any>(
    ['houseDetail', { slug }],
    fetchHouseDetail
  );

  return (
    <IonPage>
      <Header name={house?.address || ''} showBackButton />
      <IonContent>
        {!house && (
          <div className="container ion-padding ion-text-center">
            {isFetching ? 'Loading...' : error ? 'Something went wrong' : 'House not found'}
          </div>
        )}
        {house && (
          <IonList>
            <HouseItem isDetailPage house={house} />
            <Updates updates={house.updates} />
            <SimilarHouses address={house.address} />
            {house.position && <Map position={house.position} title={house.address} />}
          </IonList>
        )}
      </IonContent>
    </IonPage>
  );
};

export default HouseDetail;
