import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonAlert,
  IonIcon,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
} from '@ionic/react';
import { personCircle } from 'ionicons/icons';
import React, { FormEvent } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import { UserContext } from '../Context';
import { register } from '../utils';
import './Page.css';

const Register: React.FC = () => {
  const history = useHistory();

  const { updateUser } = React.useContext(UserContext) as UserContextType;

  const [inputs, setInputs] = React.useState<{ [key: string]: string }>({
    firstName: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  const [error, setError] = React.useState<string>('');

  const handleRegister = async (event: FormEvent) => {
    event.preventDefault();
    const { firstName, email, password, confirmPassword } = inputs;
    console.log({ firstName, email, password, confirmPassword });
    if (password !== confirmPassword) {
      setError("Passwords don't match");
      return;
    }
    const { error, user } = await register({ firstName, email, password });
    if (error) {
      setError(error);
      return;
    }
    console.log('handleLogin', user);
    updateUser(user);
    history.push('/houses');
  };

  const handleInputChange = (key: string) => (e: any) => {
    setInputs({ ...inputs, [key]: e.detail.value! });
  };

  return (
    <IonPage>
      <Header name="Register" />

      <IonContent fullscreen className="ion-padding ion-text-center">
        <IonAlert
          isOpen={!!error}
          onDidDismiss={() => setError('')}
          header={'Register Failed'}
          message={error}
          buttons={['Dismiss']}
        />
        <IonGrid>
          <form onSubmit={handleRegister}>
            <IonRow>
              <IonCol>
                <IonIcon style={{ fontSize: '70px', color: '#0040ff' }} icon={personCircle} />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position="floating">First Name</IonLabel>
                  <IonInput
                    name="firstName"
                    type="text"
                    value={inputs.firstName}
                    onIonChange={handleInputChange('firstName')}
                  ></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position="floating">Email</IonLabel>
                  <IonInput
                    name="email"
                    type="email"
                    value={inputs.email}
                    onIonChange={handleInputChange('email')}
                  ></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position="floating">Password</IonLabel>
                  <IonInput
                    name="password"
                    type="password"
                    value={inputs.password}
                    onIonChange={handleInputChange('password')}
                  ></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position="floating">Confirm Password</IonLabel>
                  <IonInput
                    name="confirmPassword"
                    type="password"
                    value={inputs.confirmPassword}
                    onIonChange={handleInputChange('confirmPassword')}
                  ></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                {/* <p style={{ fontSize: 'small' }}>
                By clicking LOGIN you agree to our <a href="#">Policy</a>
              </p> */}
                <IonButton expand="block" type="submit">
                  Register
                </IonButton>
                <p style={{ fontSize: 'medium' }}>
                  Already an account? <Link to="/login">Log in!</Link>
                </p>
              </IonCol>
            </IonRow>
            <input
              style={{ position: 'absolute', visibility: 'hidden', left: -1000 }}
              type="submit"
              className="submit-enter"
            />
          </form>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Register;
