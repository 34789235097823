import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonAlert,
  IonIcon,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
} from '@ionic/react';
import { personCircle } from 'ionicons/icons';
import React, { FormEvent } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import { UserContext } from '../Context';
import { login } from '../utils';
import './Page.css';

const Login: React.FC = () => {
  const history = useHistory();
  const { updateUser } = React.useContext(UserContext) as UserContextType;

  const [email, setEmail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [error, setError] = React.useState<string>('');

  const handleLogin = async (event: FormEvent) => {
    event.preventDefault();
    const { error, user } = await login(email, password);
    if (error) {
      setError(error);
      return;
    }
    console.log('handleLogin', user);
    updateUser(user);
    history.push('/houses');
  };

  return (
    <IonPage>
      <Header name="Login" />

      <IonContent fullscreen className="ion-padding ion-text-center">
        <IonAlert
          isOpen={!!error}
          onDidDismiss={() => setError('')}
          header={'Login Failed'}
          message={error}
          buttons={['Dismiss']}
        />
        <IonGrid>
          <form onSubmit={handleLogin}>
            <IonRow>
              <IonCol>
                <IonIcon style={{ fontSize: '70px', color: '#0040ff' }} icon={personCircle} />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position="floating">Email</IonLabel>
                  <IonInput
                    name="email"
                    type="email"
                    value={email}
                    onIonChange={(e) => setEmail(e.detail.value!)}
                  ></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position="floating"> Password</IonLabel>
                  <IonInput
                    name="password"
                    type="password"
                    value={password}
                    onIonChange={(e) => setPassword(e.detail.value!)}
                  ></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                {/* <p style={{ fontSize: 'small' }}>
                By clicking LOGIN you agree to our <a href="#">Policy</a>
              </p> */}
                <IonButton expand="block" type="submit">
                  Login
                </IonButton>
                <p style={{ fontSize: 'medium' }}>
                  Don't have an account? <Link to="/register">Sign up!</Link>
                </p>
              </IonCol>
            </IonRow>
            <input
              style={{ position: 'absolute', visibility: 'hidden', left: -1000 }}
              type="submit"
              className="submit-enter"
            />
          </form>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Login;
