export class UpdateModel {
  id: string = '';
  originalAddress: string = '';
  imageUrl: string = '';
  link: string = '';
  place: string = '';
  price: string = '';
  size: string = '';
  label: string = '';
  // houseId: string = '';
  createdAt: string = '';

  constructor(data?: any) {
    if (!data) return;

    if (data._id) {
      this.id = data._id;
    }
    if (data.imageUrl) {
      this.imageUrl = data.imageUrl;
    }
    if (data.link) {
      this.link = data.link;
    }
    if (data.place) {
      this.place = data.place;
    }
    if (data.price) {
      this.price = data.price;
    }
    if (data.size) {
      this.size = `${data.size} m²`;
    }
    if (data.label) {
      this.label = data.label;
    }
    // if (data.houseId) {
    //   this.houseId = data.houseId
    // }
    if (data.createdAt) {
      this.createdAt = data.createdAt;
    }
  }
}
