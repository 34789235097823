import React from 'react';

export const UserContext = React.createContext<UserContextType | null>(null);

export const UserContextProvider: React.FC = ({ children }) => {
  const [user, setUser] = React.useState<IUser | null>(() => {
    const userFromLocalStorage = localStorage.getItem('user');
    if (userFromLocalStorage) {
      return JSON.parse(userFromLocalStorage);
    }
    return null;
  });


  const updateUser = (newValue: IUser | null) => {
    localStorage.setItem('user', JSON.stringify(newValue));
    setUser(newValue);
  };

  const updateFavorites = (favorites: string[]) => {
    if (user) {
      setUser({ ...user, favorites });
    }
  };

  return <UserContext.Provider value={{ user, updateUser, updateFavorites }}>{children}</UserContext.Provider>;
};
