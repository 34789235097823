import React from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import { IonItemDivider, IonItemGroup } from '@ionic/react';

const loader = new Loader({
  apiKey: 'AIzaSyDXA7L6Xv2_06fjKb9yd-QI7e9aKC26LDE',
  version: 'weekly',
});

interface MapProps {
  position: IPosition;
  title: string;
}
export default function Map({ position, title }: MapProps) {
  React.useEffect(() => {
    const { latitude: lat, longitude: lng } = position;
    loader.load().then(() => {
      const map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
        center: { lat, lng },
        zoom: 14,
        scrollwheel: false,
      });

      new google.maps.Marker({
        position: { lat, lng },
        map,
        title,
      });
    });
  }, [position, title]);

  return (
    <IonItemGroup>
      <IonItemDivider color="light">Map</IonItemDivider>
      <div id="map" style={{ height: 400 }} />
    </IonItemGroup>
  );
}
