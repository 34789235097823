import React from 'react';
import { IonLabel, IonText, IonItem, IonBadge, IonItemDivider } from '@ionic/react';
import { EntryModel } from '../../models/entry.model';

export interface UpdatesProps {
  entries: EntryModel[];
}

const Entries: React.FC<UpdatesProps> = ({ entries }) => {
  const handleItemClick = (url: string) => (event: React.MouseEvent) => {
    event.stopPropagation();

    window.open(url, '_blank');
  };
  if (!entries || !entries.length) {
    return null;
  }

  return (
    <>
      <IonItemDivider color="light">Entries on funda</IonItemDivider>
      {entries.map((entry, index) => (
        <IonItem
          key={entry.houseId}
          // href={entry.link}
          button
          onClick={handleItemClick(entry.link)}
          target="_blank"
          lines={index === entries.length - 1 ? 'none' : 'inset'}
        >
          {/* <IonThumbnail slot="start" style={{ width: 90, height: 90 }}>
            <IonImg src={entry.imageUrl} />
          </IonThumbnail> */}
          <IonLabel className="ion-text-wrap">
            {entry.label !== 'nolabel' && <IonBadge color="danger">{entry.label}</IonBadge>}
            <IonText>
              <h3>
                {entry.timeLabel}&nbsp;
                {entry.originalAddress}
              </h3>

              {/* <p>
          
                <IonBadge color={entry.newPrice ? 'medium' : 'primary'}>
                <span
                  style={{
                    textDecoration: entry.newPrice ? 'line-through' : '',
                  }}
                >
                  {entry.price}
                </span>
              </IonBadge>{' '}
              {entry.newPrice && <IonBadge color="danger">{entry.newPrice}</IonBadge>}
              </p> */}
              <p>
                <b>
                  <span
                    style={{
                      textDecoration: entry.newPrice ? 'line-through' : '',
                    }}
                  >
                    {entry.price}
                  </span>{' '}
                  {entry.newPrice && entry.newPrice}
                </b>
              </p>
            </IonText>
          </IonLabel>
        </IonItem>
      ))}
    </>
  );
};

export default Entries;
