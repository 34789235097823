import axios from 'axios';
import { HouseModel } from '../models/house.model';

const apiEndpoint = 'https://fapi.erik.one';
// const apiEndpoint = 'http://localhost:3000';

const defaultHeaders = { 'Cache-Control': 'no-cache', Pragma: 'no-cache', Expires: '0' };

interface fetchHousesProps {
  searchText?: string;
  minPrice?: number;
  maxPrice?: number;
  neighbourhoods?: string[];
  minSize?: number;
  maxSize?: number;
  sort?: string;
}
export async function fetchHouses(
  key: string,
  skip: any = 0,
  { searchText, minPrice, maxPrice, neighbourhoods = [], minSize, maxSize, sort }: fetchHousesProps
): Promise<{
  houses: HouseModel[] | null;
  total?: number;
  nextId?: string | null;
}> {
  console.log({ searchText });
  try {
    const params = `?search=${searchText || ''}&minPrice=${minPrice || ''}&maxPrice=${
      maxPrice || ''
    }&neighbourhoods=${neighbourhoods.join(',')}&minSize=${minSize || ''}&maxSize=${maxSize || ''}&sort=${sort || ''}`;
    const {
      data: { error, houses, nextId, total },
    } = await axios.get(`${apiEndpoint}/houses/list/${skip}/10${params}`, {
      headers: defaultHeaders,
    });
    if (error) {
      console.log(error);
      return {
        houses: null,
        nextId: null,
      };
    }
    return {
      houses: houses.map((house: any) => new HouseModel(house)),
      total,
      nextId,
    };
  } catch (error) {
    console.log(error);
    return {
      houses: null,
      nextId: null,
    };
  }
}

export async function fetchHouseDetail(key: string, { slug }: { slug: string }) {
  try {
    const { data } = await axios.get(`${apiEndpoint}/houses/detail/${slug}`);
    if (data?.error) {
      throw new Error(data?.error);
    }
    if (!data?.house) {
      return null;
    }
    return new HouseModel(data.house);
  } catch (error) {
    console.log(error.message);
    throw new Error(error.message);
  }
}

export async function fetchFavoriteHouses(key: string, skip: any = 0, { jwToken }: { jwToken?: string }) {
  try {
    console.log('[api] >>fetchFavoriteHouses <<');
    if (!jwToken) {
      return {
        houses: null,
        nextId: null,
      };
    }
    const {
      data: { error, houses, nextId, total },
    } = await axios.get(`${apiEndpoint}/user/listFavorites/${skip}/5`, {
      headers: { ...defaultHeaders, Authorization: `Bearer ${jwToken}` },
    });
    if (error) {
      throw new Error(error);
    }
    return {
      houses: houses.map((house: any) => new HouseModel(house)),
      total,
      nextId,
    };
  } catch (error) {
    console.log(error);
    throw new Error(error.message);
  }
}

export async function fetchFavorites(key: string, { jwToken }: { jwToken: string }) {
  console.log('[api] >>fetchFavorites <<');
  if (!jwToken) {
    return {};
  }
  try {
    const { data } = await axios.get(`${apiEndpoint}/user/favorites`, {
      headers: { ...defaultHeaders, Authorization: `Bearer ${jwToken}` },
    });
    console.log('fetchFavorites', { data });

    return data;
  } catch (error) {
    console.log(error);
    return {
      error: error.message,
    };
  }
}

export async function favoriteHouse({ slug, jwToken, favorite }: { slug: string; jwToken: string; favorite: Boolean }) {
  try {
    const { data } = await axios.get(`${apiEndpoint}/user/favorite/${slug}/${favorite}`, {
      headers: { ...defaultHeaders, Authorization: `Bearer ${jwToken}` },
    });
    console.log({ data });

    return data;
  } catch (error) {
    console.log(error);
    return {
      error: error.message,
    };
  }
}

export async function login(email: string, password: string) {
  try {
    const { data } = await axios.post(`${apiEndpoint}/auth/login`, {
      email,
      password,
    });
    console.log('response', { data });
    return data;
  } catch (error) {
    return {
      error: error.message,
    };
  }
}
export async function register(form: { firstName: string; email: string; password: string }) {
  try {
    const { data } = await axios.post(`${apiEndpoint}/auth/register`, form);
    console.log('response', { data });
    return data;
  } catch (error) {
    return {
      error: error.message,
    };
  }
}

export async function fetchWoz(id: string) {
  try {
    const { data } = await axios.get(`http://localhost:3000/woz/${id}`);
    console.log('responsse', { data });
    return {
      data,
    };
  } catch (error) {
    return {};
  }
}

export async function fetchNeighbourhoods(key: string, { searchText, minPrice, maxPrice, minSize, maxSize }: IFilters) {
  try {
    const params = `?search=${
      searchText || ''
    }&minPrice=${minPrice}&maxPrice=${maxPrice}&minSize=${minSize}&maxSize=${maxSize}`;
    const { data } = await axios.get(`${apiEndpoint}/houses/neighbourhoods${params}`);
    return data?.neighbourhoods || null;
  } catch (error) {
    return null;
  }
}
