import { IonModal, IonButton } from '@ionic/react';
import React from 'react';
import { HouseModel } from '../../models/house.model';
import './HouseModal.css';

interface HouseModalProps {
  house: HouseModel | null;
  closeModal: Function;
}
const HouseModal: React.FC<HouseModalProps> = ({ house, closeModal }) => {
  return (
    <IonModal isOpen={!!house} cssClass="my-custom-class" onDidDismiss={() => closeModal()}>
      {house && <iframe is="x-frame-bypass" src={house.link} title="House Previe" height="100%" width="100%"></iframe>}
      {/* {house && <object type="text/html" data={house.link} width="800px" height="600px" style={{ overflow: 'auto', border: '5px ridge blue' }} />} */}
      <IonButton onClick={() => closeModal()}>Close Modal</IonButton>
    </IonModal>
  );
};

export default HouseModal;
