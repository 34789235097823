import { UpdateModel } from './update.model';
import { EntryModel } from './entry.model';

export class HouseModel {
  id: string = '';
  address: string = '';
  imageUrl: string = '';
  link: string = '';
  place: string = '';
  price: string = '';
  size: string = '';
  slug: string = '';
  label: string = '';
  neighbourhood: string = '';
  position?: IPosition;
  updates: UpdateModel[] = [];
  createdAt: string = '';
  woz?: IWOZ;
  newPrice: string | null = null;
  favorite: Boolean = false;
  entries: EntryModel[] = [];

  constructor(data?: any) {
    if (!data) return;

    if (data._id) {
      this.id = data._id;
    }
    if (data.address) {
      this.address = data.address;
    }
    if (data.imageUrl) {
      this.imageUrl = data.imageUrl;
    }
    if (data.place) {
      this.place = data.place;
    }
    if (data.price) {
      this.price = data.price;
    }
    if (data.size) {
      this.size = `${data.size} m²`;
    }
    if (data.slug) {
      this.slug = data.slug;
    }
    if (data.updates) {
      this.updates = [...data.updates]
        .reverse()
        .filter(({ houseId }) => !houseId?.includes('parkeergelegenheid'))
        .map((update: any) => new UpdateModel(update));
      this.newPrice = this.updates.find((update) => update.price)?.price || null;
    }
    this.label = this.updates.find((update) => update.label)?.label || data.label || null;
    this.link = data.link;
    if (data.createdAt) {
      this.createdAt = data.createdAt;
    }
    if (data.position) {
      this.position = data.position;
      this.neighbourhood = data.position.neighbourhood;
    }
    if (data.woz) {
      this.woz = data.woz;
    }
    if (data.favorite) {
      this.favorite = data.favorite;
    }
    if (data.entries) {
      this.entries = [...data.entries].reverse().map((entry: any) => new EntryModel(entry));
    }

    // Add orininal entry to entry list
    const originalInEntry = this.entries.some((entry) => entry.link === this.link);
    if (!originalInEntry) {
      this.entries.push(new EntryModel({ ...data, originalAddress: `${data.originalAddress}*`, updates: [] }));
    }
  }
}
