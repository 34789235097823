import React, { useRef, Fragment } from 'react';
import { IonList, IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/react';
import { useInfiniteQuery } from 'react-query';
import { UserContext } from '../../Context';
import { fetchFavoriteHouses } from '../../utils/api';
import { HouseModel } from '../../models/house.model';
import HouseItem from './HouseItem';
import { Link } from 'react-router-dom';

interface FavoriteHouseListProps {
  onCountChange: (count: number | null) => void;
}

const FavoriteHouseList: React.FC<FavoriteHouseListProps> = ({ onCountChange }) => {
  const { user } = React.useContext(UserContext) as UserContextType;
  const ionInfiniteScrollRef = useRef<HTMLIonInfiniteScrollElement>(null);

  const { canFetchMore, data, error, isFetching, fetchMore } = useInfiniteQuery(
    ['favoriteHouses', { jwToken: user?.jwToken }],
    async (key: string, variables, nextId: any = 0) => {
      const data = await fetchFavoriteHouses(key, nextId, variables);
      if (ionInfiniteScrollRef.current) ionInfiniteScrollRef.current.complete();
      return data;
    },
    {
      getFetchMore: (lastGroup) => lastGroup.nextId,
      staleTime: 0,
      onSuccess: (data: any[]) => {
        const [{ total }] = data;
        if (total) {
          onCountChange(total);
        }
      },
    }
  );

  React.useEffect(() => {
    onCountChange(null);
    return () => {
      onCountChange(null);
    };
  }, [user]);

  if (!user) {
    return (
      <div className="container ion-padding ion-text-center">
        Please <Link to="/login">login</Link> to see favorites
      </div>
    );
  }
  if (!data?.length) {
    return (
      <div className="container ion-padding ion-text-center">
        {isFetching ? 'Loading...' : error ? 'Something went wrong' : 'No data yet'}
      </div>
    );
  }

  return (
    <>
      <div className="container">
        <IonList>
          {data.map(({ houses, nextId }) => (
            <Fragment key={nextId}>
              {houses && houses.map((house: HouseModel) => <HouseItem key={house.id} house={house} />)}
            </Fragment>
          ))}
        </IonList>
        <IonInfiniteScroll
          threshold="100px"
          disabled={!canFetchMore}
          ref={ionInfiniteScrollRef}
          onIonInfinite={() => fetchMore()}
        >
          <IonInfiniteScrollContent loadingText="Loading more houses..." />
        </IonInfiniteScroll>
      </div>
    </>
  );
};

export default FavoriteHouseList;
