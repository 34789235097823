import React from 'react';
// import Axios from 'axios';
import { queryCache } from 'react-query';
import {
  IonItem,
  IonImg,
  IonBadge,
  IonButton,
  IonLabel,
  IonText,
  IonThumbnail,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
} from '@ionic/react';
import { useHistory } from 'react-router';
import Entries from './Entries';
import WozButton from './WozButton';
import Woz from './Woz';
import { heartOutline, heart } from 'ionicons/icons';
import { UserContext } from '../../Context';
import { HouseModel } from '../../models/house.model';
import { favoriteHouse } from '../../utils/api';
interface HouseItemProps {
  isDetailPage?: Boolean;
  house: HouseModel;
  searchText?: string | null;
}

const HouseItem: React.FC<HouseItemProps> = ({ house, isDetailPage }) => {
  const history = useHistory();
  const { user } = React.useContext(UserContext) as UserContextType;
  const handleFavoriteClick = (favorite: Boolean) => async (event: React.MouseEvent) => {
    event.stopPropagation();
    if (!user?.jwToken) {
      return;
    }
    const data = await favoriteHouse({ slug: house.slug, jwToken: user?.jwToken, favorite });
    console.log('[HouseItem] handleFavoriteClick', { data });
    queryCache.refetchQueries('favorites');
    queryCache.refetchQueries('favoriteHouses');
  };

  function handleItemClick() {
    if (isDetailPage) {
      return;
    }
    history.push(`/house/${house.slug}`, { goBack: true });
  }

  const isFavorite = !!user?.favorites?.includes(house.slug);

  return (
    <>
      <IonItem button={!isDetailPage} class="ion-align-items-start">
        <IonThumbnail slot="start" style={{ width: 180, height: 180 }} onClick={handleItemClick}>
          <IonImg src={house.imageUrl} />
        </IonThumbnail>
        <IonGrid>
          <IonRow>
            <IonCol onClick={handleItemClick}>
              <IonLabel className="ion-text-wrap">
                {house.label && house.label !== 'nolabel' && (
                  <>
                    <IonBadge color="danger">{house.label}</IonBadge>{' '}
                  </>
                )}
                {house.neighbourhood && <IonBadge color="light">{house.neighbourhood}</IonBadge>}
                <IonText color="primary"></IonText>
                <hr />
                <h2>{house.address}</h2>
                <h3>{house.place}</h3>
                <hr />
                <IonText>
                  <p>
                    <b>
                      <span style={{ textDecoration: house.newPrice ? 'line-through' : '' }}>{house.price}</span>{' '}
                      {house.newPrice && house.newPrice}
                    </b>
                  </p>
                  <p>
                    {house.size && `${house.size} | `}Created at {new Date(house.createdAt).toDateString()}
                  </p>
                  {user && (
                    <IonButton color={isFavorite ? 'danger' : 'light'} onClick={handleFavoriteClick(!isFavorite)}>
                      <IonIcon slot="icon-only" icon={house.favorite ? heart : heartOutline} />
                    </IonButton>
                  )}
                  {user?.email === 'erik.weihs@gmail.com' && <WozButton id={house.id} />}
                </IonText>
              </IonLabel>
            </IonCol>
            <IonCol onClick={(event: React.MouseEvent) => event.stopPropagation()}>
              <Woz woz={house.woz} />
              <Entries entries={house.entries} />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonItem>
      {/* <IonGrid>
        <IonRow>
          <IonCol>
            <Woz woz={house.woz} />
          </IonCol>
          <IonCol>
            <Entries entries={house.entries} />
          </IonCol>
        </IonRow>
      </IonGrid> */}
    </>
  );
};

export default HouseItem;
