import React from 'react';

const STORAGE_KEY = 'FILTERS';

const defaultValues: IFilters = (function () {
  try {
    const storage = localStorage.getItem(STORAGE_KEY);
    if (storage) {
      return JSON.parse(storage);
    }
  } catch (ignore) {}
  return {
    searchText: '',
    minPrice: 100000,
    maxPrice: 850000,
    minSize: 75,
    maxSize: 150,
    neighbourhoods: [],
  };
})();
export const FilterContext = React.createContext<FilterContextType | null>(null);

export const FilterContextProvider: React.FC = ({ children }) => {
  const [filters, setFilters] = React.useState<IFilters>(defaultValues);

  const updateFilters = (props: IFilters) => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify({ ...filters, ...props }));
    setFilters({ ...filters, ...props });
  };

  return <FilterContext.Provider value={{ filters, updateFilters }}>{children}</FilterContext.Provider>;
};
