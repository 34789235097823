import React, { useRef } from 'react';
import { IonContent, IonPage } from '@ionic/react';
import { HouseList } from '../components/HouseList';

import Header from '../components/Header';

import './Page.css';

const Houses: React.FC = () => {
  const [count, setCount] = React.useState<number | null>(null);
  const contentRef = useRef<HTMLIonContentElement>(null);

  function handleCountChange(newValue: number | null) {
    if (newValue !== count) {
      setCount(newValue);
    }
  }

  return (
    <IonPage>
      <Header name="Houses" count={count} />
      <IonContent ref={contentRef} scrollEvents={true}>
        <HouseList onCountChange={handleCountChange} />
      </IonContent>
    </IonPage>
  );
};

export default Houses;
