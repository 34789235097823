import React from 'react';
import {
  IonButton,
  IonButtons,
  IonHeader,
  IonMenuButton,
  IonPopover,
  IonTitle,
  IonToolbar,
  IonItem,
  IonLabel,
  IonIcon,
} from '@ionic/react';
import { chevronBack } from 'ionicons/icons';
import { useHistory } from 'react-router';
import { UserContext } from '../../Context';
interface HeaderProps {
  count?: number | null;
  name: string;
  showBackButton?: boolean;
}
const Header: React.FC<HeaderProps> = ({ showBackButton, count, name }) => {
  const history = useHistory();

  const [popoverState, setShowPopover] = React.useState({ showPopover: false, event: undefined });
  const { user, updateUser } = React.useContext(UserContext) as UserContextType;

  React.useEffect(() => {
    document.title = `${name} | erik.one`;
  }, [name]);

  function handleUserClick(e: any) {
    if (user) {
      e.persist();
      setShowPopover({ showPopover: true, event: e });
      return;
    }
    history.push('/login');
  }

  function handleLogout() {
    updateUser(null);
    setShowPopover({ showPopover: false, event: undefined });
  }

  function handleBackClick() {
    if ((history.location.state as any)?.goBack) {
      history.goBack();
      return;
    }
    history.push('/houses');
  }

  return (
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton />
          {/* routerLink="/houses" routerDirection="back" */}
          {showBackButton && (
            <IonButton onClick={handleBackClick}>
              <IonIcon icon={chevronBack} />
            </IonButton>
          )}
        </IonButtons>
        <IonTitle>
          {name} {count && `(${count})`}
        </IonTitle>
        <IonButtons slot="end">
          <>
            <IonPopover
              event={popoverState.event}
              isOpen={popoverState.showPopover}
              onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}
            >
              <IonItem button onClick={handleLogout}>
                <IonLabel>Logout</IonLabel>
              </IonItem>
            </IonPopover>
            <IonButton onClick={handleUserClick}>{user ? user.firstName : 'Login'}</IonButton>
          </>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;
