import React, { useRef, Fragment, useCallback, useState } from 'react';
import { IonList, IonInfiniteScroll, IonInfiniteScrollContent, IonChip, IonIcon, IonLabel } from '@ionic/react';
import { useInfiniteQuery } from 'react-query';
import { FilterContext } from '../../Context';
import { fetchHouses } from '../../utils/api';
import { HouseModel } from '../../models/house.model';
import HouseModal from '../HouseModal';
import HouseItem from './HouseItem';
import { cash, close, cube, search } from 'ionicons/icons';
import { numberWithCommas } from '../../utils';

interface HouseListProps {
  onCountChange: (count: number | null) => void;
}

const HouseList: React.FC<HouseListProps> = ({ onCountChange }) => {
  const { filters, updateFilters } = React.useContext(FilterContext) as FilterContextType;
  const ionInfiniteScrollRef = useRef<HTMLIonInfiniteScrollElement>(null);
  const [openHouse, setOpenHouse] = useState<HouseModel | null>(null);
  const { canFetchMore, data, error, isFetching, fetchMore } = useInfiniteQuery(
    ['houses', filters],
    async (key: string, variables, nextId: any = 0) => {
      const data = await fetchHouses(key, nextId, variables);
      if (ionInfiniteScrollRef.current) ionInfiniteScrollRef.current.complete();
      return data;
    },
    {
      getFetchMore: (lastGroup) => lastGroup.nextId,
      staleTime: 0,
      onSuccess: (data: any[]) => {
        const [{ total }] = data;
        if (total) {
          onCountChange(total);
        }
      },
    }
  );

  React.useEffect(() => {
    onCountChange(null);
    return () => {
      onCountChange(null);
    };
  }, []);

  const handleShowHouse = useCallback(
    (house: HouseModel | null) => () => {
      setOpenHouse(house);
    },
    []
  );

  const handleChipClick = (key: string, value: any = '') => () => {
    updateFilters({ [key]: value });
  };

  const { minPrice, maxPrice, searchText, minSize, maxSize } = filters;

  return (
    <>
      <HouseModal house={openHouse} closeModal={handleShowHouse(null)} />

      <div className="container">
        <div className="ion-padding">
          {searchText && (
            <IonChip outline color="dark" onClick={handleChipClick('searchText')}>
              <IonIcon size="small" icon={search} />
              <IonLabel>Search: {searchText}</IonLabel>
              <IonIcon icon={close} />
            </IonChip>
          )}
          {minPrice !== 0 && (
            <IonChip outline color="dark" onClick={handleChipClick('minPrice', 0)}>
              <IonIcon size="small" icon={cash} />
              <IonLabel>Min Price: {numberWithCommas(minPrice)}€</IonLabel>
              <IonIcon icon={close} />
            </IonChip>
          )}
          {maxPrice !== 1500000 && (
            <IonChip outline color="dark" onClick={handleChipClick('maxPrice', 1500000)}>
              <IonIcon icon={cash} />
              <IonLabel>Max Price: {numberWithCommas(maxPrice)}€</IonLabel>
              <IonIcon icon={close} />
            </IonChip>
          )}
          {minSize !== 0 && (
            <IonChip outline color="dark" onClick={handleChipClick('minSize', 0)}>
              <IonIcon size="small" icon={cube} />
              <IonLabel>Min Size: {minSize} m²</IonLabel>
              <IonIcon icon={close} />
            </IonChip>
          )}
          {maxSize !== 300 && (
            <IonChip outline color="dark" onClick={handleChipClick('maxSize', 300)}>
              <IonIcon icon={cube} />
              <IonLabel>Max Size: {maxSize} m²</IonLabel>
              <IonIcon icon={close} />
            </IonChip>
          )}
        </div>
        {!data?.length && (
          <div className="container ion-padding ion-text-center">
            {isFetching ? 'Loading...' : error ? 'Something went wrong' : 'No data yet'}
          </div>
        )}
        <IonList>
          {data.map(({ houses, nextId }) => (
            <Fragment key={nextId as string}>
              {houses && houses.map((house: HouseModel) => <HouseItem key={house.id} house={house} />)}
            </Fragment>
          ))}
        </IonList>
        <IonInfiniteScroll
          threshold="100px"
          disabled={!canFetchMore}
          ref={ionInfiniteScrollRef}
          onIonInfinite={() => fetchMore()}
        >
          <IonInfiniteScrollContent loadingText="Loading more houses..." />
        </IonInfiniteScroll>
      </div>
    </>
  );
};

export default HouseList;
